<template>
  	<div class="dynamic_item pay_order_container">
	  	<div class="pay_box empty">
		  	<p class="order_text good_order_text" v-if="userOrderData.id">
        		Заказ № 
        		<span class="pay_order_number">{{userOrderData.id}}</span> 
        		{{userOrderData.status}}
      		</p>
			<p class="order_text bad_order_text" v-if="badText">{{badText}}</p>
		  	<div class="qr_pay_container" v-if="userOrderData.code" v-html="userOrderData.code"></div>
			<p class="qr_code_warning" v-if="userOrderData.code">Не закрывайте страницу, покажите <span style="text-transform: uppercase; font-weight: 900;">QR-код</span> продавцу!</p>
      		<a class="check_pay_button" target="_blank"
			  	v-for="item in userOrderData.check" 
				:href="item.url" 
				:key="item.id">{{item.name}}
			</a>
      		<a class="pay_button" v-if="userOrderData.pay" :href="userOrderData.pay" target="_blank">Оплатить</a>
	  	</div>
    	<router-link to="/orders" class="classic_button color_button back_to_user_orders"><span>Назад</span></router-link>
	</div>
</template>

<script>
import axios from 'axios'
import VueCookies from 'vue-cookies'

export default {
  	name: 'Order',
  	data() {
    	return {
     		keyEIN: '',
      		userOrderData: {},
			badText: '',
    	}
  	},
  	props: {
	  	id: String,
  	},
  	methods: {
    	showTrouble() {
			this.badText = 'Что-то пошло не так :('
    	},
    	checkEmptyObject(obj) {
      		for(var prop in obj) {
        		if(Object.prototype.hasOwnProperty.call(obj, prop)) {
          			return false;
        		}
      		}

      		return JSON.stringify(obj) === JSON.stringify({});
    	},
    	getOrderData(val) {
      		let path = 'https://platillo.ru/api/v1/user/order/' + val + '/get'
      		let cookieData = VueCookies.get("ein-x")

     		let headers = {
        		'x-val-use': 'Z4M2goU2cvvUQUE4ZWh70Cu6XfrwY1',
      		}

      		if (cookieData) {
        		headers['ein-x'] = cookieData
      		}

    		axios.get(
        		path,
        		{headers: headers},
    		).then((value) => {
        		console.log('Success answer /user/order/')
        		console.dir(value)

				let checkUrl = value.data.data['check_url'];
				let payUrl = value.data.data['pay_url'];
				let qrCode = value.data.data['qr_code'];
				let statusName = value.data.data['status_name'];
				let id = value.data.data.id;

				let payBox = document.querySelector('.pay_box')
				payBox.classList.remove('empty')

				this.badText = ''

				this.userOrderData.id = id
				this.userOrderData.check = checkUrl
				this.userOrderData.pay = payUrl
				this.userOrderData.code = qrCode
				this.userOrderData.status = statusName
      		}).catch((value) => {
				this.$router.push('../orders')
      		})
    	}
  	},
  	mounted() {
    	let val = this.$route.params.id

		if (val) {
			this.getOrderData(val)
		} else {
			this.$route.push('../orders')
		}

    	window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
		  	'event': 'Drink',
		  	'eventCategory': 'Drink',
		  	'eventAction': 'Buy',
		  	'eventLabel': 'Success'
		});
  	},
}
</script>

<style >
    .pay_order_container:before {
      content: '';
      display: block;
      width: 100%;
      height: 30rem;
      background-color: var(--color-2);
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 1;
    }
    .order_text {
      display: block;
      width: 100%;
      height: auto;
      text-align: left;
      font-size: 3rem;
      line-height: 1em;
      font-weight: 400;
      margin-top: 3rem;
      padding-right: 6vw;
      box-sizing: border-box;
      text-transform: uppercase;
    }
    .order_text .current_order_number {
      font-weight: 900;
      font-size: 1.1em;
    }
    .pay_order_number {
      font-size: 1.2em;
      font-weight: 700;
    }
    .qr_pay_container {
      display: block;
      width: 30rem;
      height: 30rem;
      margin-top: 1.5rem;
    }
    .qr_pay_container svg {
      width: 100% !important;
      height: 100% !important;
    }
    .qr_code_warning {
      display: block;
      width: 100%;
      height: auto;
      text-align: left;
      font-size: 2.3rem;
      line-height: 1.2em;
      color: var(--color-1);
      font-weight: 600;
      margin-top: 2rem;
    }
    .pay_box {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      height: auto;
      padding: 0 6vw;
      box-sizing: border-box;
      margin-top: 5rem;
    }
	.pay_box.empty {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding-top: 5rem;
    }
    .pay_box.empty:before {
        content: '';
        display: block;
        width: 10rem;
        height: 10rem;
        border-radius: 50%;
        border: 2px dashed var(--color-1);
        animation: empty_products 3s linear infinite;
    }
    .pay_box.empty:after {
        content: 'Загрузка';
        display: block;
        width: 100%;
        height: auto;
        margin-top: 2rem;
        font-size: 3rem;
        line-height: 1.1em;
        font-weight: 500;
        color: var(--color-1);
    }
    @keyframes  empty_products {
        0% {
            transform: rotate(0);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    .pay_box.good_pay:before {
      content: '';
      display: block;
      width: 5rem;
      height: 5rem;
      background-image: url(../assets/img/icon_ok.png);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      border-radius: 50%;
      box-shadow: 0px 2px 2px rgba(50, 50, 71, 0.06), 0px 2px 4px rgba(50, 50, 71, 0.06);
    }
    .back_to_user_orders {
      margin-left: 6vw;
      margin-top: 5rem;
    }
    .check_pay_button,
    .pay_button {
      display: block;
      width: auto;
      height: auto;
      text-align: left;
      font-size: 4rem;
      line-height: 1.1em;
      font-weight: 700;
      color: var(--color-1);
      margin-top: 3rem;
      text-decoration: none;
      border-bottom: 1px solid var(--color-1);
      cursor: pointer;
    }
</style>